import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/generalUtility';

const intitalState = {
  error: false,
};

const failedLoad = (state) => {
  return updateObject(state, {
    error: true,
  });
};


const reducer = (state = intitalState, action) => {
  switch (action.type) {
    case actionTypes.FAILED_LOAD:
      return failedLoad(state, action);
    default:
      return state;
  }
};

export default reducer;
