import React from "react";
import { Navbar } from 'react-bootstrap';
import { connect } from "react-redux";
import ToolTitle from "../../ToolTitle/ToolTitle";

const Toolbar = () => (
    <>
        <Navbar bg="light" expand="lg" style={{height:'64px'}}>
            <ToolTitle>User Data Platform</ToolTitle>
        </Navbar>
    </>
);

export default connect()(Toolbar);
