import React from 'react';
import styles from './NavigationItem.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

const navigationItem = (props) => {
  if (props.link.startsWith("http")) {
    return (
      <li className={styles.listItem}>
        <a href={`${props.link}`} className={styles.hrefLink}>
          <FontAwesomeIcon className={styles.icon} icon={`${props.icon}`} />
            <h4 className={styles.linkTitle}>{`${props.title}`}</h4>
        </a>
      </li>
    );
  }
  else {
    return (
      <div className={styles.newListItem}>
        <li className={styles.listItem}>
          <NavLink to={`${props.link}`} exact activeClassName={styles.selected} className={styles.hrefLink}>
          <FontAwesomeIcon className={styles.icon} icon={`${props.icon}`} />
            <h4 className={styles.linkTitle}>{`${props.title}`}</h4>
          </NavLink>
        </li>
      </div>
    );
  }
};

export default navigationItem;