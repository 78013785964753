import React from 'react';
import Logo from '../../assets/images/lv-logo.svg';
import styles from './Logo.module.css';
import {Link} from 'react-router-dom';

const logo = (props) => (
  <Link to="/">
    <div className={styles.logo}>
      <img src={Logo} alt="LeadVenture Marketing" />
    </div>
  </Link>
);

export default logo;