import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <div className={styles.footer}>
      <p>{process.env.REACT_APP_NAME}</p>
      <p>Version: {process.env.REACT_APP_VERSION} © LeadVenture 2020 -{' '} {new Date().getFullYear()}</p>
    </div>
  );
}
 
export default Footer;