import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/generalUtility';

const initialState = {
    rows: [],
    filteredRows: [],
    refData: {
        offices: [],
        positions: [],
        platforms: [],
        departments: []
    },
    editMode: false,
    dataLoaded: false
};

const loadOverviewDashboard = (state, action) => {
    let userList = [];
    if(action.result) {
        action.result.forEach(ele => {
            if(ele){
                userList.push({
                    id: ele.user_id, 
                    name: {
                        firstName: ele.first_name, 
                        lastName: ele.last_name
                    }, 
                    department: ele.department,
                    position: ele.position,
                    platform: ele.platform,
                    office: ele.office,
                    podlead: ele.pod_lead ? ele.pod_lead : "N/A",
                    manager: ele.manager !== null ? ele.manager : "N/A",
                    email: ele.email,
                    startDate: ele.start_date,
                    endDate: ele.end_date !== null ? ele.end_date : "N/A",
                    nextReviewDate: ele.next_review_date !== null ? ele.next_review_date : "N/A",
                    phoneExt: ele.phone_ext,
                    status: ele.active.data[0] === 1 ? "Active" : "Inactive"
                });
            }
        });    
    }
    
    return updateObject(state, {
        rows: userList,
        filteredRows: userList,
        editMode: false,
        dataLoaded: true
    });
}

const storeRefData = (state, action) => {
    let updatedState = {
        refData: {
            offices: action.result.locationData,
            positions: action.result.positionData,
            platforms: action.result.platformData,
            departments: action.result.departmentData
        }
    }
    return updateObject(state, updatedState);
}

const editModeSwitcher = (state, action) => {
    let newValue = !state.editMode;
    if(action.input === "false") {
        newValue = false;
    }
    return updateObject(state, {editMode: newValue});
}

const sortRows = (initialRows, sortColumn, sortDirection) => {
    let rows = initialRows;
    let comparer = (a, b) => {
        if (sortDirection === "ASC") {
          return a[sortColumn] > b[sortColumn] ? 1 : -1;
        } else if (sortDirection === "DESC") {
          return a[sortColumn] < b[sortColumn] ? 1 : -1;
        }
    };
    if(sortColumn === "name") {
        comparer = (a, b) => {
            if (sortDirection === "ASC") {
              return a["name"]["firstName"] > b["name"]["firstName"] ? 1 : -1;
            } else if (sortDirection === "DESC") {
              return a["name"]["firstName"] < b["name"]["firstName"] ? 1 : -1;
            }
        };
    }
    return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
};

const updateGrid = (state, action) => {
    let updatedRows = sortRows(state.rows, action.sortColumn, action.sortDirection);
    let updatedFilteredRows = sortRows(state.filteredRows, action.sortColumn, action.sortDirection);
    let updatedState = updateObject(state, {
        rows: updatedRows,
        filteredRows: updatedFilteredRows
    });
    return updateObject(state, updatedState);
};

const filterRows = (state, action) => {
    let updatedState = updateObject(state, {
        filteredRows: action.updatedFilteredRows
    });
    return updateObject(state, updatedState);
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_GRID: return updateGrid(state, action);
        case actionTypes.FILTER_ROWS: return filterRows(state, action);
        case actionTypes.EDIT_MODE_SWITCHER : return editModeSwitcher(state, action);
        case actionTypes.INIT_OVERVIEW_DASHBOARD : return loadOverviewDashboard(state, action);
        case actionTypes.STORE_REF_DATA : return storeRefData(state, action);
        default: return state;
    }
};

export default reducer;